import ResponsiveWebp from 'components/ResponsiveWebp'

import { HOST_CDN } from '../config'
import s from '../EnglishHome.module.css'

export default function MotivationSection() {
  return (
    <section className={s.motivation}>
      <div className={s.imgContainer}>
        <ResponsiveWebp
          pc={{
            base: `${HOST_CDN}img_motivation_250404.png`,
            webp: `${HOST_CDN}img_motivation_250404.webp`
          }}
          mo={{
            base: `${HOST_CDN}img_m_motivation_250404.png`,
            webp: `${HOST_CDN}img_m_motivation_250404.webp`
          }}
          alt={'동기부여 장학금으로 완강률 3배 상승'}
          maxWidth={1024}
        />
      </div>
    </section>
  )
}
