import React from 'react'
import { render } from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'

import MetaInfo from 'components/common/MetaInfo'

import App from './App'
import reportWebVitals from './reportWebVitals'

import 'core-js/stable'
import 'regenerator-runtime/runtime'

const rootElement = document.getElementById('root')

render(
  <React.StrictMode>
    <HelmetProvider>
      <MetaInfo />
      <App />
    </HelmetProvider>
  </React.StrictMode>,
  rootElement
)

reportWebVitals()
