// pc = {base: '', webp: ''}
// mo = {base: '', webp: ''}
// alt = string
// maxWidth = Int
// lazy = boolean
export default function ResponsiveWebp({ pc, mo, alt, maxWidth = 1024, lazy = true, ...props }) {
  return (
    <picture {...props}>
      {/* 모바일 이미지 */}
      {mo && (
        <>
          {mo.webp && (
            <source media={`(max-width: ${maxWidth}px)`} srcSet={mo.webp} type="image/webp" />
          )}
          <source media={`(max-width: ${maxWidth}px)`} srcSet={mo.base} />
        </>
      )}

      {/* 데스크톱 이미지 */}
      {pc.webp && <source srcSet={pc.webp} type="image/webp" />}
      <img src={pc.base} alt={alt ? alt : 'Image'} loading={lazy ? 'lazy' : 'eager'} />
    </picture>
  )
}
