import { Link } from 'react-router-dom'

import ResponsiveImage from 'components/ResponsiveImage'

import { HOST_CDN } from '../config'
import s from '../EnglishHome.module.css'

export default function LevelTestSection() {
  return (
    <section className={s.levelTest}>
      <div className={s.inner}>
        <Link className={s.btnLvTest} to={'/levelTestAi/start'}>
          <ResponsiveImage
            pc={`${HOST_CDN}btn_level_test.png`}
            mo={`${HOST_CDN}btn_m_level_test.png`}
            alt={'레벨테스트'}
            maxWidth={1024}
          />
        </Link>
      </div>
    </section>
  )
}
