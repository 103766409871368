import React, { useEffect, useState, useCallback, useRef } from 'react'
import TagManager from 'react-gtm-module'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'

import ModalPortal from 'ModalPortal'
import AuthService from 'services/authService'

import api from 'common/api'
import * as config from 'common/config'
import { setCookie, getCookie } from 'common/Cookie'
import Counter from 'components/Counter'
import ModalCouponDownload from 'components/modal/ModalCouponDownload'
import ModalCourse from 'components/modal/ModalCourse'
import ModalEntSale from 'components/modal/ModalEntSale'
import 'assets/styles/detailCards.scss'
import ModalInstallments from 'components/modal/ModalInstallments'
import ModalShare from 'components/modal/ModalShare'
import useModalLogin from 'store/useModalLogin'

import styles from './PriceInfo.module.scss'
import ModalMallAuth from '../mall/ModalMallAuth'

const MINIMUM_PRICE = 50_000
const MINIMUM_MONTH = 1
// const PDT_AUDIO = 852;
// const PDT_TRIP = 1882;

// 정가 : exceptOptionOriginalPrice
// 일반판매가 : exceptOptionSalePrice
// 입학판매 월 할부금액 : monthlyMembershipSalePrice

export default function PriceInfo({ classData, entrance, mallCode }) {
  const navigate = useNavigate()
  const [isEntrancedUser, setIsEntrancedUser] = useState(false) // 입학 여부
  const [isEntrancedCheck, setIsEntrancedCheck] = useState(false) // 입학 체크여부
  const [modalopen2, setModalopen2] = useState(false)
  const [modalopen3, setModalopen3] = useState(false)
  const [modalEntSale, setModalEntSale] = useState(false)
  const [modalCourse, setModalCourse] = useState(false)
  const [openPriceBox, setOpenPriceBox] = useState(false)
  const [isModalMallAuth, setModalMallAuth] = useState(false)
  const [isMallCheck, setMallCheck] = useState(false)
  const [isMallFilterCheck, setMallFilterCheck] = useState(false)
  const [isProductSeq, setProductSeq] = useState('')
  const [couponDownloadData, setCouponDownloadData] = useState([])
  const [installmentsModal, setInstallmentsModal] = useState(false)
  const { setIsModalLogin } = useModalLogin()
  const couponList = AuthService.getUserInfo() ? couponDownloadData : classData.downloadCouponInfos
  const mallFilterList = ['college']
  const mallFilterCheck = (code) => {
    let result = false
    mallFilterList.forEach((item) => {
      if (code === item) result = true
    })
    return result
  }

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 })

    return isMobile ? children : null
  }

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1024 })

    return isDesktop ? children : null
  }

  const addComma = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const handlePriceBox = () => {
    setOpenPriceBox(!openPriceBox)
  }

  const isMounted = useRef(true)

  // 유저정보 가져오기
  const getUserInfo = () => {
    if (AuthService.getUserInfo()) {
      api
        .get(config.AUTH_API_HOST + '/auth/v2/user')
        .then((response) => {
          if (isMounted.current) {
            setIsEntrancedUser(response.data.data?.entrance?.entranceStatus === 'ENTRANCE')
            setIsEntrancedCheck(true)
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  // 다운로드 가능한 쿠폰 API
  const getCouponDownloadData = async (packageSeq) => {
    let couponViewList = []
    try {
      if (classData.existDownloadCoupon && classData.existDownloadCoupon === 'Y') {
        await api
          .get(`/v2/coupon/possible/download/list?keys=packageSeq&types=equal&values=${packageSeq}`)
          .then((response) => {
            couponViewList = response.data.data.content
          })
          .catch((e) => {
            console.log(e)
          })
      }
      await api
        .get(`/v2/coupon-publish/list?index=1&size=100&types&keys=couponUseYn&types=equal&values=N`)
        .then((response) => {
          const couponData = response.data.data.content
          couponData.forEach((item) => {
            item.couponToPackageInfoDTOList.forEach((detailItem) => {
              if (Number(detailItem.packageSeq) === Number(packageSeq)) {
                item.getCouponYn = true
                couponViewList.push(item)
              }
            })
          })
        })
        .catch((e) => {
          console.log(e)
        })
      if (isMounted.current) {
        setCouponDownloadData(couponViewList)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenModalLogin = useCallback((e) => {
    document.body.classList.add('modal-open')
    setCookie('orderClickCheck', 'Y')
    setIsModalLogin(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpenModal2 = useCallback((e) => {
    // 로그인 여부 체크
    if (!AuthService.getUserInfo()) {
      alert('로그인이 필요합니다.')
      window.location.href = '/login'
    } else {
      document.body.classList.add('modal-open')
      setModalopen2(true)
    }
  }, [])

  const handleCloseModal2 = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopen2(false)
  }, [])

  const handleOpenModal3 = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopen3(true)
  }, [])

  const handleCloseModal3 = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopen3(false)
  }, [])

  const handleCloseModalEntSale = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalEntSale(false)
  }, [])

  const handleOpenModalCourse = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalCourse(true)
  }, [])

  const handleCloseModalCourse = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalCourse(false)
  }, [])

  const handleOpenModalMallAuth = useCallback((e) => {
    document.body.classList.add('modal-open')
    document.body.classList.add('scroll-fixed')
    setModalMallAuth(true)
  }, [])

  const handleCloseModalMallAuth = useCallback((e) => {
    document.body.classList.remove('modal-open')
    document.body.classList.remove('scroll-fixed')
    setModalMallAuth(false)
  }, [])

  const handleOpenModalInstallments = useCallback((e) => {
    document.body.classList.add('modal-open')
    setInstallmentsModal(true)
  }, [])

  const handleCloseModalInstallments = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setInstallmentsModal(false)
  }, [])
  const showErrorMessage = (response) => {
    if (response.data.meta.message) {
      alert(response.data.meta.message)
    } else {
      alert(config.MESSAGE['common-error'])
    }
  }

  const mallInfoComplete = (mallCategory, mallDetail) => {
    handleCloseModalMallAuth()
    directOrder(isProductSeq, mallCategory, mallDetail)
  }

  const orderStart = (productSeq) => {
    typeof productSeq === `string` && productSeq.length > 0
      ? setProductSeq(productSeq)
      : setProductSeq(``)

    if (isMallFilterCheck) {
      handleOpenModalMallAuth()
      return
    }
    directOrder(productSeq)
  }

  const directOrder = (productSeq, mallCategory, mallDetail) => {
    // console.log('🚀  productSeq, mallCategory, mallDetail:', productSeq, mallCategory, mallDetail)
    const packageSeq = classData.packageSeq
    const goodsParam =
      typeof productSeq === `string` && productSeq.length > 0
        ? `?presentProductSeq=${productSeq}`
        : ``

    // 주문번호 재셋팅을 위한 productParam 값 셋팅.
    window.sessionStorage.setItem(
      'productParam',
      JSON.stringify({ param: `${packageSeq}${goodsParam}`, orderReset: false })
    )

    api
      .post(`/payment/v2/order/${packageSeq}${goodsParam}`)
      .then((response) => {
        if (response.data.meta?.code === 200) {
          navigate(`/mypage/order/cart/detail/${response.data.data}`, {
            state: { mallCode, mallCategory, mallDetail }
          })
        } else {
          showErrorMessage(response)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const dataChkPriceInfo = () => {
    try {
      if (AuthService.getUserInfo()) getCouponDownloadData(classData.packageSeq)
      if (classData.packageSeq) getUserInfo()
      if (window['checkURL'] !== window.location.href && classData.packageSeq !== undefined) {
        window['dataChkPriceInfo'](
          classData.title,
          !!document.getElementById('productPrice')
            ? Number(
                document.getElementById('productPrice').innerText.replace(',', '').replace(',', '')
              )
            : 0,
          classData.packageCategoryGroupName + ', ' + classData.packageCategoryName,
          classData.packageSeq
        )
        window['checkURL'] = window.location.href

        TagManager.dataLayer({
          dataLayer: {
            event: 'detailpage'
          }
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    classData.mallCode && classData.mallCode !== '' ? setMallCheck(true) : setMallCheck(false) // 몰인몰 여부 체크.
    classData.mallCode && mallFilterCheck(classData.mallCode)
      ? setMallFilterCheck(true)
      : setMallFilterCheck(false) // 몰인몰 필터 체크.
    dataChkPriceInfo()

    return () => {
      isMounted.current = false
      document.body.classList.remove('scroll-fixed')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isEntrancedCheck) {
      // 렌더링 후 orderClickCheck, orderLoginSuccess 쿠키값 체크, 로그인 여부 값을 체크합니다.
      // 1. getCookie('orderClickCheck') === 'Y' : 비로그인 상태에서 수강신청버튼을 클릭한 경우를 체크하기 위한 목적.
      // 2. getCookie('orderLoginSuccess') === 'Y' : 로그인에 성공했으며, reloadType 인 경우를 체크하기 위한 목적. (Login.jsx)
      // 3. AuthService.getUserInfo() : 유저 정보 유무를 체크하기 위한 목적.
      // 1~3 모두 true 라면 .btn-cart 버튼을 클릭 시켜 결제 화면 or 입학 팝업이 노출되도록 처리하고, 쿠키값과 state 값을 초기화 합니다.
      if (
        getCookie('orderClickCheck') === 'Y' &&
        getCookie('orderLoginSuccess') === 'Y' &&
        AuthService.getUserInfo()
      ) {
        if (document.querySelector('.btn-cart')) {
          setCookie('orderLoginSuccess', 'N')
          setCookie('orderClickCheck', 'N')
          document.querySelector('.btn-cart').click()
        }
      }
    }
  }, [isEntrancedCheck])

  return (
    <>
      <section className="price-info-col">
        <div className="info-inner">
          {/* 상품상세 타이틀 영역 */}
          <div className={styles.priceInfoTitle}>
            <p>
              {classData.saleTypeCode === 'EARLY_BIRD' && <span className="early">얼리버드</span>}
              <em className="category">
                {classData.packageCategoryGroupName}
                &middot;
                {classData.packageCategoryName}
              </em>
              <em className="leader">
                {classData.teacherInfo}
                {classData.packageTypeCode === 'REAL' ? '준비물' : null}
              </em>
            </p>
            <p className="ellipsis line-clamp-2">
              <strong>{classData.title}</strong>
            </p>
            {isMallCheck ? (
              <>
                {classData.packageTypeCode === 'CLASS' ||
                classData.packageTypeCode === 'COMBINATION' ? (
                  <dl className={styles.periodInfo}>
                    <dt className={styles.period}>수강기간</dt>
                    <dd>{classData.periodInfo}</dd>
                  </dl>
                ) : null}
              </>
            ) : (
              <p className="score">
                <span>{classData.reviewSatisfaction}</span>
                <em>{classData.reviewCount >= 100 ? '100+' : classData.reviewCount}</em>
              </p>
            )}
          </div>
          {/* 정가, 일반 판매가 */}
          {classData.saleTypeCode === 'EARLY_BIRD' ? (
            <div className="price-box">
              <div className="type early-bird">
                <p>
                  <em className="discount">{addComma(Number(classData.exceptOptionSalePrice))}</em>
                </p>
                <p>
                  <mark>{classData.exceptOptionEarlyBirdDiscountPercent}&#37;</mark>
                  <strong>{addComma(Number(classData.exceptOptionEarlyBirdSalePrice))}</strong>
                </p>
                {/* 일반 회원 가격과 입학 회원 가격이 다를경우 */}
                {classData.exceptOptionSalePrice !== classData.exceptOptionMembershipSalePrice && (
                  <p style={{ marginTop: '1rem' }}>
                    <strong>{addComma(Number(classData.exceptOptionMembershipSalePrice))}</strong>
                  </p>
                )}
              </div>
            </div>
          ) : isMallCheck ? (
            <div className="price-box">
              <div className={'type membership' + (entrance ? ' entrance' : '')}>
                <dl>
                  <dt>정가</dt>
                  <dd>
                    {classData.exceptOptionSalePrice <= MINIMUM_PRICE ||
                    classData.installmentMonth <= MINIMUM_MONTH ? (
                      <strong id="productPrice">
                        {addComma(Math.floor(Number(classData.exceptOptionOriginalPrice)))}
                      </strong>
                    ) : (
                      <strong id="productPrice">
                        월{' '}
                        {addComma(
                          Math.floor(
                            Number(classData.exceptOptionOriginalPrice / classData.installmentMonth)
                          )
                        )}
                      </strong>
                    )}
                  </dd>
                </dl>
                <dl>
                  <dt>판매가</dt>
                  <dd>
                    <>
                      {classData.exceptOptionMembershipSalePrice < MINIMUM_PRICE ||
                      classData.installmentMonth <= MINIMUM_MONTH ? (
                        <strong className="no-month">
                          {addComma(Number(classData.exceptOptionMembershipSalePrice))}
                        </strong>
                      ) : (
                        <>
                          <span>{addComma(Number(classData.exceptOptionSalePrice))}원</span>
                          <br />
                          <span className="percent">
                            {Math.round(
                              ((Number(classData.exceptOptionOriginalPrice) -
                                Number(classData.exceptOptionSalePrice)) /
                                Number(classData.exceptOptionOriginalPrice)) *
                                100
                            )}
                            %
                          </span>{' '}
                          <strong>{addComma(Number(classData.monthlySalePrice))}</strong>
                          <em>{classData.installmentMonth}</em>
                        </>
                      )}
                    </>
                  </dd>
                </dl>
              </div>
            </div>
          ) : (
            <div className="price-box">
              <div className={'type membership' + (entrance ? ' entrance' : '')}>
                <dl>
                  <dt>정가</dt>
                  <dd>
                    {classData.exceptOptionOriginalPrice <= MINIMUM_PRICE ||
                    classData.installmentMonth <= MINIMUM_MONTH ? (
                      <strong id="productPrice">
                        {addComma(Number(classData.exceptOptionOriginalPrice))}
                      </strong>
                    ) : (
                      <strong id="productPrice">
                        월{' '}
                        {addComma(
                          Math.floor(
                            Number(classData.exceptOptionOriginalPrice / classData.installmentMonth)
                          )
                        )}
                      </strong>
                    )}
                  </dd>
                </dl>
                {isEntrancedUser ? (
                  <React.Fragment>
                    <dl className={styles.defaultPriceDl}>
                      <dt>일반 판매가</dt>
                      <dd>
                        {classData.exceptOptionSalePrice <= MINIMUM_PRICE ||
                        classData.installmentMonth <= MINIMUM_MONTH ? (
                          <React.Fragment>
                            <span>
                              {Math.floor(
                                ((Number(classData.exceptOptionOriginalPrice) -
                                  Number(classData.exceptOptionSalePrice)) /
                                  Number(classData.exceptOptionOriginalPrice)) *
                                  100
                              )}
                              %
                            </span>
                            <strong>{addComma(Number(classData.exceptOptionSalePrice))}</strong>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <span>
                              {Math.floor(
                                ((Number(
                                  classData.exceptOptionOriginalPrice / classData.installmentMonth
                                ) -
                                  Number(
                                    classData.exceptOptionSalePrice / classData.installmentMonth
                                  )) /
                                  Number(
                                    classData.exceptOptionOriginalPrice / classData.installmentMonth
                                  )) *
                                  100
                              )}
                              %
                            </span>
                            <strong>
                              월{' '}
                              {addComma(
                                Math.floor(
                                  Number(
                                    classData.exceptOptionSalePrice / classData.installmentMonth
                                  )
                                )
                              )}
                            </strong>
                          </React.Fragment>
                        )}
                      </dd>
                    </dl>
                    <dl>
                      <dt>입학회원</dt>
                      <dd>
                        <>
                          {classData.exceptOptionMembershipSalePrice < MINIMUM_PRICE ||
                          classData.installmentMonth <= MINIMUM_MONTH ? (
                            <strong className={`${styles.priceStrong} no-month`}>
                              <mark>{classData.exceptOptionDiscountPercent}&#37;</mark>
                              {addComma(Number(classData.exceptOptionMembershipSalePrice))}
                            </strong>
                          ) : (
                            <>
                              {classData.exceptOptionSalePrice !==
                              classData.exceptOptionMembershipSalePrice ? (
                                <>
                                  <mark>{classData.exceptOptionDiscountPercent}&#37;</mark>
                                  <strong className={styles.priceStrong}>
                                    월 {addComma(Number(classData.monthlyMembershipSalePrice))}
                                  </strong>
                                </>
                              ) : (
                                <strong className={styles.priceStrong}>
                                  월 {addComma(Number(classData.monthlySalePrice))}
                                </strong>
                              )}
                            </>
                          )}
                        </>
                      </dd>
                    </dl>
                    <dl className={styles.salePrice}>
                      <dt>
                        <span>입학특가</span>
                      </dt>
                      <dd>
                        {Number(MINIMUM_PRICE) >= classData.exceptOptionMembershipSalePrice ? (
                          <strong>
                            정가대비{' '}
                            {addComma(
                              classData.exceptOptionOriginalPrice -
                                classData.exceptOptionMembershipSalePrice
                            )}
                            원 할인
                          </strong>
                        ) : (
                          <strong>
                            정가대비 월{' '}
                            {addComma(
                              Math.floor(
                                (classData.exceptOptionOriginalPrice -
                                  classData.exceptOptionMembershipSalePrice) /
                                  classData.installmentMonth
                              )
                            )}
                            원 할인
                          </strong>
                        )}
                      </dd>
                    </dl>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <dl>
                      <dt>일반 판매가</dt>
                      <dd>
                        <>
                          {classData.exceptOptionSalePrice < MINIMUM_PRICE ||
                          classData.installmentMonth <= MINIMUM_MONTH ? (
                            <strong className={`${styles.priceStrong} no-month`}>
                              <mark>
                                {Math.floor(
                                  ((Number(
                                    classData.exceptOptionOriginalPrice / classData.installmentMonth
                                  ) -
                                    Number(
                                      classData.exceptOptionSalePrice / classData.installmentMonth
                                    )) /
                                    Number(
                                      classData.exceptOptionOriginalPrice /
                                        classData.installmentMonth
                                    )) *
                                    100
                                )}
                                &#37;
                              </mark>
                              {addComma(Number(classData.exceptOptionSalePrice))}
                            </strong>
                          ) : (
                            <strong className={styles.priceStrong}>
                              월 {addComma(Number(classData.monthlySalePrice))}
                            </strong>
                          )}
                        </>
                      </dd>
                    </dl>
                    <dl className={styles.salePrice}>
                      <dt>
                        <span>타임특가</span>
                      </dt>
                      <dd>
                        {Number(MINIMUM_PRICE) >= classData.exceptOptionSalePrice ? (
                          <strong>
                            정가대비{' '}
                            {addComma(
                              classData.exceptOptionOriginalPrice - classData.exceptOptionSalePrice
                            )}
                            원 할인
                          </strong>
                        ) : (
                          <strong>
                            정가대비 월{' '}
                            {addComma(
                              Math.floor(
                                (classData.exceptOptionOriginalPrice -
                                  classData.exceptOptionSalePrice) /
                                  classData.installmentMonth
                              )
                            )}
                            원 할인
                          </strong>
                        )}
                      </dd>
                    </dl>
                  </React.Fragment>
                )}
                <button
                  type="button"
                  className={styles.btnInstallmentsInfo}
                  onClick={handleOpenModalInstallments}
                >
                  <span>최대 12개월 무이자 할부 혜택</span>
                </button>
              </div>
            </div>
          )}
          {couponList && couponList.length > 0 ? (
            <div className={styles.couponDownloadBox}>
              <p>이 상품에 적용할 수 있는 쿠폰</p>
              <button
                type="button"
                className={styles.btnDownload}
                onClick={!AuthService.getUserInfo() ? handleOpenModalLogin : handleOpenModal2}
              >
                쿠폰받기
              </button>
            </div>
          ) : null}
          {isMallCheck ? null : (
            <>
              {classData.packageTypeCode === 'CLASS' ||
              classData.packageTypeCode === 'COMBINATION' ? (
                <div className="curriculum-box">
                  <p>{classData.contentInfo}</p>
                  <p className="period-info">{classData.periodInfo}</p>
                  <p className="class-level-info">{classData.classLevelInfo}</p>
                </div>
              ) : null}
              {classData.packageTypeCode === 'APP' ? (
                <div className="curriculum-box">
                  <p className="period-info">{classData.periodInfo}</p>
                </div>
              ) : null}
            </>
          )}
          <Desktop>
            <div className="btn-group active">
              {isEntrancedUser ? (
                !entrance ? (
                  <button
                    type="button"
                    className="btn-cart active"
                    onClick={classData.displaySaleStatus === 'SALE' ? orderStart : undefined}
                  >
                    입학 특가로 시작하기
                  </button>
                ) : (
                  <button type="button" className="btn-default" disabled>
                    이미 입학 회원입니다.
                  </button>
                )
              ) : classData.displaySaleStatus === 'SALE' ? (
                <button
                  type="button"
                  className="btn-cart active"
                  onClick={
                    !AuthService.getUserInfo()
                      ? handleOpenModalLogin // 로그인 모달
                      : classData.requireEntranceYn === 'Y' // 입학 포함 상품
                      ? handleOpenModalCourse // 입학 안내 모달
                      : orderStart // 구매
                  }
                >
                  할인 받고 시작하기
                </button>
              ) : classData.displaySaleStatus === 'END' ? (
                <button type="button" className="btn-default" disabled>
                  일시 품절
                </button>
              ) : (
                <button type="button" className="btn-default" disabled>
                  판매 예정
                </button>
              )}
              <button type="button" className="btn-share" onClick={handleOpenModal3}>
                공유
              </button>
            </div>
          </Desktop>
          <Mobile>
            <div className="mobile-fixed-box">
              {classData.saleTypeCode === 'EARLY_BIRD' ? (
                <>
                  <div className={'price-box' + (openPriceBox ? ' active' : '')}>
                    <div className="type early-bird">
                      <p>
                        <em className="discount">
                          {addComma(Number(classData.exceptOptionSalePrice))}
                        </em>
                      </p>
                      <p>
                        {classData.exceptOptionEarlyBirdDiscountPercent !== 0 && (
                          <mark>
                            {classData.exceptOptionEarlyBirdDiscountPercent}
                            &#37;
                          </mark>
                        )}
                        <strong>
                          {addComma(Number(classData.exceptOptionEarlyBirdSalePrice))}
                        </strong>
                      </p>
                      {classData.exceptOptionSalePrice !==
                        classData.exceptOptionMembershipSalePrice && (
                        <p>
                          <strong>
                            {addComma(Number(classData.exceptOptionMembershipSalePrice))}
                          </strong>
                        </p>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <React.Fragment>
                  <div className={'price-box' + (openPriceBox ? ' active' : '')}>
                    {classData.packageTypeCode === 'REAL' ? (
                      <>
                        <div className="type material">
                          <span>수량</span>
                          <Counter />
                        </div>
                        <div className="type">
                          <p>
                            {/* <mark>
                          {classData.exceptOptionDiscountPercent}
                          &#37;
                        </mark> */}
                            <em className="discount">
                              {addComma(Number(classData.exceptOptionSalePrice))}
                            </em>
                          </p>
                          <p>
                            <strong>
                              {addComma(Number(classData.exceptOptionMembershipSalePrice))}
                            </strong>
                          </p>
                        </div>
                      </>
                    ) : (
                      <React.Fragment>
                        <dl className={styles.bottomPriceInfo}>
                          <dt>정가</dt>
                          <dd>
                            {classData.exceptOptionOriginalPrice <= MINIMUM_PRICE ||
                            classData.installmentMonth <= MINIMUM_MONTH ? (
                              <strong id="productPrice">
                                {addComma(Number(classData.exceptOptionOriginalPrice))}
                              </strong>
                            ) : (
                              <strong id="productPrice">
                                월{' '}
                                {addComma(
                                  Math.floor(
                                    Number(
                                      classData.exceptOptionOriginalPrice /
                                        classData.installmentMonth
                                    )
                                  )
                                )}
                              </strong>
                            )}
                          </dd>
                        </dl>
                        {isEntrancedUser ? (
                          <React.Fragment>
                            {/* 입학 true */}
                            <dl className={`${styles.bottomPriceInfo} ${styles.itemMiddle}`}>
                              <dt>일반 판매가</dt>
                              <dd>
                                {classData.exceptOptionSalePrice <= MINIMUM_PRICE ||
                                classData.installmentMonth <= MINIMUM_MONTH ? (
                                  <React.Fragment>
                                    <span>
                                      {Math.floor(
                                        ((Number(classData.exceptOptionOriginalPrice) -
                                          Number(classData.exceptOptionSalePrice)) /
                                          Number(classData.exceptOptionOriginalPrice)) *
                                          100
                                      )}
                                      %
                                    </span>
                                    <strong>
                                      {addComma(Number(classData.exceptOptionSalePrice))}
                                    </strong>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <span>
                                      {Math.floor(
                                        ((Number(
                                          classData.exceptOptionOriginalPrice /
                                            classData.installmentMonth
                                        ) -
                                          Number(
                                            classData.exceptOptionSalePrice /
                                              classData.installmentMonth
                                          )) /
                                          Number(
                                            classData.exceptOptionOriginalPrice /
                                              classData.installmentMonth
                                          )) *
                                          100
                                      )}
                                      %
                                    </span>
                                    <strong>
                                      월{' '}
                                      {addComma(
                                        Math.floor(
                                          Number(
                                            classData.exceptOptionSalePrice /
                                              classData.installmentMonth
                                          )
                                        )
                                      )}
                                    </strong>
                                  </React.Fragment>
                                )}
                              </dd>
                            </dl>
                            <dl className={`${styles.bottomPriceInfo} ${styles.lineTop}`}>
                              <dt>입학회원</dt>
                              <dd className="total">
                                <>
                                  {classData.exceptOptionMembershipSalePrice < MINIMUM_PRICE ||
                                  classData.installmentMonth <= MINIMUM_MONTH ? (
                                    <strong className={`${styles.priceStrong} no-month`}>
                                      <mark>{classData.exceptOptionDiscountPercent}&#37;</mark>
                                      {addComma(Number(classData.exceptOptionMembershipSalePrice))}
                                    </strong>
                                  ) : (
                                    <>
                                      {classData.exceptOptionSalePrice !==
                                      classData.exceptOptionMembershipSalePrice ? (
                                        <>
                                          <mark>{classData.exceptOptionDiscountPercent}&#37;</mark>
                                          <strong className={styles.priceStrong}>
                                            월{' '}
                                            {addComma(Number(classData.monthlyMembershipSalePrice))}
                                          </strong>
                                        </>
                                      ) : (
                                        <strong className={styles.priceStrong}>
                                          월 {addComma(Number(classData.monthlySalePrice))}
                                        </strong>
                                      )}
                                    </>
                                  )}
                                </>
                              </dd>
                            </dl>
                            {isMallCheck ? null : (
                              <dl className={styles.salePrice}>
                                <dt>
                                  <span>입학특가</span>
                                </dt>
                                <dd>
                                  {Number(MINIMUM_PRICE) >=
                                  classData.exceptOptionMembershipSalePrice ? (
                                    <strong>
                                      정가대비{' '}
                                      {addComma(
                                        classData.exceptOptionOriginalPrice -
                                          classData.exceptOptionMembershipSalePrice
                                      )}
                                      원 할인
                                    </strong>
                                  ) : (
                                    <strong>
                                      정가대비 월{' '}
                                      {addComma(
                                        Math.floor(
                                          (classData.exceptOptionOriginalPrice -
                                            classData.exceptOptionMembershipSalePrice) /
                                            classData.installmentMonth
                                        )
                                      )}
                                      원 할인
                                    </strong>
                                  )}
                                </dd>
                              </dl>
                            )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {/* 입학 false */}
                            <dl className={`${styles.bottomPriceInfo} ${styles.lineTop}`}>
                              <dt>일반 판매가</dt>
                              <dd className="total">
                                <>
                                  {classData.exceptOptionMembershipSalePrice < MINIMUM_PRICE ||
                                  classData.installmentMonth <= MINIMUM_MONTH ? (
                                    <strong className={`${styles.priceStrong} no-month`}>
                                      <mark>
                                        {Math.floor(
                                          ((Number(
                                            classData.exceptOptionOriginalPrice /
                                              classData.installmentMonth
                                          ) -
                                            Number(
                                              classData.exceptOptionSalePrice /
                                                classData.installmentMonth
                                            )) /
                                            Number(
                                              classData.exceptOptionOriginalPrice /
                                                classData.installmentMonth
                                            )) *
                                            100
                                        )}
                                        &#37;
                                      </mark>
                                      {addComma(Number(classData.exceptOptionMembershipSalePrice))}
                                    </strong>
                                  ) : (
                                    <>
                                      {classData.exceptOptionSalePrice !==
                                      classData.exceptOptionMembershipSalePrice ? (
                                        <>
                                          <mark>
                                            {Math.floor(
                                              ((Number(
                                                classData.exceptOptionOriginalPrice /
                                                  classData.installmentMonth
                                              ) -
                                                Number(
                                                  classData.exceptOptionSalePrice /
                                                    classData.installmentMonth
                                                )) /
                                                Number(
                                                  classData.exceptOptionOriginalPrice /
                                                    classData.installmentMonth
                                                )) *
                                                100
                                            )}
                                            &#37;
                                          </mark>
                                          <strong className={styles.priceStrong}>
                                            월{' '}
                                            {addComma(
                                              Math.floor(
                                                Number(
                                                  classData.exceptOptionSalePrice /
                                                    classData.installmentMonth
                                                )
                                              )
                                            )}
                                          </strong>
                                        </>
                                      ) : (
                                        <strong className={styles.priceStrong}>
                                          월 {addComma(Number(classData.monthlySalePrice))}
                                        </strong>
                                      )}
                                    </>
                                  )}
                                </>
                              </dd>
                            </dl>
                            {isMallCheck ? null : (
                              <dl className={styles.salePrice}>
                                <dt>
                                  <span>타임특가</span>
                                </dt>
                                <dd>
                                  {Number(MINIMUM_PRICE) >=
                                  classData.exceptOptionMembershipSalePrice ? (
                                    <strong>
                                      정가대비{' '}
                                      {addComma(
                                        classData.exceptOptionOriginalPrice -
                                          classData.exceptOptionSalePrice
                                      )}
                                      원 할인
                                    </strong>
                                  ) : (
                                    <strong>
                                      정가대비 월{' '}
                                      {addComma(
                                        Math.floor(
                                          (classData.exceptOptionOriginalPrice -
                                            classData.exceptOptionSalePrice) /
                                            classData.installmentMonth
                                        )
                                      )}
                                      원 할인
                                    </strong>
                                  )}
                                </dd>
                              </dl>
                            )}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </React.Fragment>
              )}
              <div className="btn-group">
                <div className="btn-flex-form">
                  <button type="button" className="btn-share" onClick={handleOpenModal3}>
                    공유
                  </button>
                  {isEntrancedUser ? (
                    !entrance ? (
                      <button
                        type="button"
                        className="btn-cart active"
                        onClick={classData.displaySaleStatus === 'SALE' ? orderStart : undefined}
                      >
                        입학 특가로 시작하기
                      </button>
                    ) : (
                      <button type="button" className="btn-default" disabled>
                        이미 입학 회원입니다.
                      </button>
                    )
                  ) : classData.displaySaleStatus === 'SALE' ? (
                    <button
                      type="button"
                      className="btn-cart active"
                      onClick={
                        !AuthService.getUserInfo()
                          ? handleOpenModalLogin // 로그인 모달
                          : classData.requireEntranceYn === 'Y' // 입학 포함 상품
                          ? handleOpenModalCourse // 입학 안내 모달
                          : orderStart // 구매
                      }
                    >
                      할인 받고 시작하기
                    </button>
                  ) : classData.displaySaleStatus === 'END' ? (
                    <button type="button" className="btn-soldout" disabled>
                      일시 품절
                    </button>
                  ) : (
                    <button type="button" className="btn-waiting" disabled>
                      판매 예정
                    </button>
                  )}
                </div>
              </div>
              <button className="btn-open" type="button" onClick={handlePriceBox}>
                <i className="blind">상세정보 보기</i>
              </button>
            </div>
          </Mobile>
        </div>
      </section>
      {modalopen2 ? (
        <ModalPortal>
          {couponDownloadData && (
            <ModalCouponDownload
              btnClose
              handleCloseModal={handleCloseModal2}
              couponDownloadData={couponDownloadData}
              getCouponDownloadData={getCouponDownloadData}
              priceInfoSeq={classData.packageSeq}
            />
          )}
        </ModalPortal>
      ) : null}

      {modalopen3 ? (
        <ModalPortal>
          <ModalShare
            title={'페이지를'}
            snsShareTitle={classData.title}
            btnClose
            data={classData}
            banner={classData.packageImages[0]}
            handleCloseModal={handleCloseModal3}
          />
        </ModalPortal>
      ) : null}

      {modalEntSale ? (
        <ModalPortal>
          <ModalEntSale
            btnClose
            data={classData}
            MINIMUM_PRICE={MINIMUM_PRICE}
            MINIMUM_MONTH={MINIMUM_MONTH}
            handleCloseModal={handleCloseModalEntSale}
          />
        </ModalPortal>
      ) : null}

      {modalCourse ? (
        <ModalPortal>
          <ModalCourse
            btnClose
            goodsDirectOrder={orderStart}
            handleCloseModal={handleCloseModalCourse}
            showErrorMessage={showErrorMessage}
          />
        </ModalPortal>
      ) : null}

      {isModalMallAuth ? (
        <ModalPortal>
          <ModalMallAuth
            mallType={classData.mallCode}
            handleCloseModal={handleCloseModalMallAuth}
            mallInfoComplete={mallInfoComplete}
            packageSeq={classData.packageSeq}
          />
        </ModalPortal>
      ) : null}
      {installmentsModal ? (
        <ModalPortal>
          <ModalInstallments
            handleClose={handleCloseModalInstallments}
            btnClose
            title={'무이자 할부 혜택'}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}
